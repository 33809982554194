<template>
    <div class="pageNotFond text-center">
        <div>
            <va-image src="/img/pageNotFound.jpg"></va-image>
        </div>
        <div class="display-6">Oops! Page not found.</div>
        <va-button @click="$router.push('/event')" class="homeBtn mt-5">Home</va-button>
    </div>
</template>
<script>
export default {
    data(){
        return{
            
        }
    }
}
</script>
<style scoped lang='scss'>
@import "../assets/main.scss";
.pageNotFond{
    @include relativeCenter();
    .homeBtn{
        margin: 0 auto;
        width: 100px;
    }
}
</style>